.post {
  padding: 3rem 0;

  &-info {
    color: $default-tint;
    font-family: $serif-secondary;
    letter-spacing: 0.5px;
    text-align: center;

    span {
      font-style: italic;
    }
  }

  &-title {
    color: $default-shade;
    font-family: $sans-serif;
    font-size: 3rem;
    margin: 1rem 0;
    text-align: center;
  }

  &-line {
    border-top: 0.4rem solid $default-shade;
    display: block;
    margin: 0 auto 3rem;
    width: 4rem;
  }

  p {
    margin: 0 0 1rem;
    text-align: justify;
  }

  a:hover {
    text-decoration: underline;
  }

  img {
    margin: 0 auto 0.5rem;
  }

  img + em {
    color: $default-tint;
    display: block;
    font-family: $sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    text-align: center;
  }

  img.emoji {
    display: inline-block;
    left: 0;
    transform: none;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
    padding: 0;
    margin: 0;
  }

  img[alt=medium] { width: 60%; }

  mjx-container * {
    line-height: 0;
  }

  .section-nav::before {
    content: "Table of Contents";
  }

  .section-nav {
    font-weight: bold;
    color: #000;
    background-color: #f5f5f5;
    margin: 5px 0;
    padding: 15px 35px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
  }

  .section-nav > li:first-child {
    margin-top: 20px;
  }

  .section-nav a {
    font-weight: normal;
    color: $default-color ;
    line-height: 30px;
  }

  .tooltip {
    position: relative;
    cursor: pointer;
    border-bottom: 1px dotted $default-color;
  }

  .tooltip span {
    visibility: hidden;
    width: 20em;
    background-color: $default-color;
    font-size: 12px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 9;
    top: -1em;
    left:  100%;
    margin-left:1em;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .tooltip span::after {
    content: "";
    position: absolute;
    top: 1.5em;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $default-color transparent transparent;
  }

  .tooltip input {
    display:none;
  }

  .tooltip input:checked+span {
    visibility: visible;
    opacity: 1;
  }
}
